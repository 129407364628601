import React, { Component } from 'react';
import Layout from '../components/layout'
import Footer from '../components/footer'
import { graphql } from "gatsby"
import Img from 'gatsby-image'
import SEO from '../components/seo'


import { Row, Col } from 'antd'

class Club extends Component {
    constructor(props){
        super(props);
        this.state = {
            navbar: 'top',
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if(window.scrollY > 100){
            this.setState({navbar: 'no-top'})
        } else {
            this.setState({navbar: 'top'})
        }
    };

    render() {
        return (
            <Layout onScroll={this.handleScroll} position={this.state.navbar} currentPage='club'>
                <SEO title="Shalee, l'agent digital équestre" keywords={[`Shalee`,`Application`,`Mobile`,`Cheval`,`Chevaux`,`Club`,`Equestre`,`Equitation`,`Cavalier`]}/>
                <div style={{paddingTop: '100px'}}>
                    <div style={{textAlign:"center", margin:"50px", marginBottom:"100px"}}>
                        <h1 className="title" style={{textAlign:"center"}}>Devenez un club digitalisé</h1>
                        <h1 style={{textAlign:"center"}}>NOTRE ENGAGEMENT : vous faire gagner du temps.</h1>
                    </div>
                    <Row className="container"  type="flex" justify="space-around" align="top" gutter={50}>
                        <Col xs={34} sm={34} md={12} lg={12} xl={12}>
                            <h3>VOTRE INTERFACE PERSONNALISÉE</h3>
                            <p>Grâce à votre interface web, bénéficiez de tous les outils pour gérer vous-même les services de votre club auprès de votre communauté de cavaliers et offrez leur une expérience unique.</p>
                        <br/>
                        </Col>
                        <Col xs={34} sm={34} md={12} lg={12} xl={12}>
                            <h3>TABLEAU DE BORD</h3>
                            <p>Grâce à Shalee, venez comprendre tout l’écosystème de votre club (Qui monte ? À quelle fréquence ? Pour quel tarif ?). Nous vous offrons tous les outils pour donner le meilleur.</p>
                            <br/>
                        </Col>
                        <Col xs={34} sm={34} md={12} lg={12} xl={12}>
                            <h3>ACCOMPAGNEMENT AU QUOTIDIEN</h3>
                            <p>Parce que l’on sait bien que Rome ne s’est pas fait en deux jours, on vous accompagne au quotidien pour que vous puissiez vivre votre transformation et en ressentir tous les bénéfices. Alors surtout n’hésitez pas. On est là pour vous.</p>
                            <br/>

                        </Col>
                        <Col xs={34} sm={34} md={12} lg={12} xl={12}>
                            <h3>OPTIMISEZ VOTRE FACTURATION</h3>
                            <p>Gérez vous-même la facturation et générez toutes les factures dont vous avez besoin en un clic. Finis les interminables soirées à équilibrer les comptes. En quelques clics l’affaire est dans le mac.</p>
                            <br/>

                        </Col>
                        <Col xs={34} sm={34} md={12} lg={12} xl={12}>
                            <h3>PLANIFICATION EN TEMPS RÉEL</h3>
                            <p>Ajoutez ou annulez un cours, déplacez un second et permettez à vos cavaliers d’être notifiés de chaque action effectuée pour être au courant des news en temps réel.</p>
                            <br/>

                        </Col>
                    </Row>
                    <Img fluid={this.props.data.imageClub.childImageSharp.fluid} style={{minWidth: '300px', maxWidth:"600px", margin:"auto"}}/>

                    <Footer/>
                </div>
            </Layout>
        );
    }
}

export default Club;

export const pageQuery = graphql`
  query {
    imageClub: file(relativePath: { eq: "mac-club.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
    }
  }
`